module.exports = [{
      plugin: require('/var/lib/jenkins/workspace/lyn_Site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135931953-1","head":false},
    },{
      plugin: require('/var/lib/jenkins/workspace/lyn_Site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/jenkins/workspace/lyn_Site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
